import * as React from "react"

import { LoadingSpinner } from "@behaviour-lab/blab-component-library/components"
import { NextPage } from "next"
import { useRouter } from "next/router"

import { useInvoker } from "src/utils/hooks"

const HomePage: NextPage = () => {
  const router = useRouter()
  const { invoker, isLoading, error } = useInvoker()

  React.useEffect(() => {
    if (!isLoading && !error && invoker?.organizations?.length) {
      router.push(`/${invoker.organizations[0].id}`)
    }
  }, [invoker, isLoading, error, router])

  // Show the same loading spinner whether we are redirecting or loading
  return (
    <div
      className="items-center justify-center h-screen centered-container"
      data-testid="home-page"
    >
      {error ? <div>Error: {error?.toString()}</div> : <LoadingSpinner />}
    </div>
  )
}

export default HomePage
